/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Grid,
    Pagination as MuiPagination,
    Stack,
    Tab,
    Tabs,
    ThemeProvider,
    Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import FilterRoot from '../../components/filter/FilterRoot';
import StyledAutocomplete from '../../components/styled-autocomplete';
import useStyledAutocomplete from '../../components/styled-autocomplete/useStyledAutocomplete';
import {
    companyController,
    locationController,
    regionController,
    tenantController,
    vehicleListController,
    zoneController,
} from '../../controllers';
import {
    FilterVehicleListByPermitTypeProps,
    FilterVehicleListProps,
} from '../../controllers/vehicleList/VehicleListController';
import { search as searchValue } from '../../helpers';
import useDbSearch from '../../hooks/useDbSearch';
import {
    FilterLocation,
    FilterObject,
    FilterRequest,
    Pagination,
    PartiallyOptional,
} from '../../models/filter/BaseFilter';
import { Company } from '../../models/scope/Company';
import { Region } from '../../models/scope/Region';
import Zone from '../../models/scope/Zone';

import PropTypes from 'prop-types';
import IMultiSelectWithCheckBox from '../../components/autoComplete/IMultiSelectWithCheckBox';
import PopUpWarning from '../../components/PopUpWarning';
import { Location } from '../../models/scope/Location';
import { VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { GFlexRowCenter, TitleWithBackButton } from '../../styles/style';
import theme from '../../theme/Theme';
import { SearchField } from '../vehicleListDetails/StartAdornment';
import VehicleListItem, { VehicleListItemAction } from './components/VehicleListItem';
import { Search, SearchType, searchTypes } from './model/SearchType';
import LoadingWrap from '../../components/LoadingWrap';
import { pushError, pushSuccess } from '../../components/toast';
import { AuthContext, AuthPermission } from '../../layout/AuthLayout';
import LinkTo from '../../components/LinkTo';
import { useVehicleListParams } from '../vehicleListDetails';
import color from '../../config/Colors';
import { appConfig } from '../../config/AppConfig';
import { LoadingButton } from '@mui/lab';
import { Tenant } from '../../models/scope/Tenant';
// import { serviceController } from '../../controllers';
import { ServiceContext, ServicePermit } from '../../providers/ServiceProvider';
import { Service } from '../../models/vehicleList/Service';
import { useParams, useSearchParams } from 'react-router-dom';
import AutocompleteNoPagi from '../../components/styled-autocomplete/MyAutocomplete';
import { intersection, isNull } from 'lodash';
import { PermitTypeVl } from '../../models/permit/Permit';
import LoadingWrap2 from '../../components/LoadingWrap2';

const permitScopes = [
    {
        name: 'All lists',
        id: -1,
    },
    {
        name: 'Only global list',
        id: 0,
    },
    {
        name: 'Only list with specific zones',
        id: 1,
    },
];
const statusFilter = [
    {
        name: 'Active',
        id: 1,
    },
    {
        name: 'Inactive',
        id: 2,
    },
    {
        name: 'Expired',
        id: 3,
    },
];

const initState: State = {
    page: 1,
    pageSize: 10,
    filter: {
        zones: [],
        scopeType: permitScopes[0],
        tenants: [],
    },
    search: {
        type: searchTypes[1],
        value: '',
    },
};

export type VehicleListPageProps = {
    zoneId?: string;
    locationId?: string;
};

export type VehicleListEmbedProps = VehicleListPageProps & {
    onAddVehicleList(): void;
    vehicleListItemAction?: VehicleListItemAction;
};
const initPagination = {
    page: 1,
    data: [],
    pageSize: 10,
    total: 0,
    totalPage: 0,
};
export type SearchParams = {
    locationId: string | number;
    zoneIds: string | number;
    searchBy: string | number;
    search: string | number;
    companyId: string | number;
    region: string | number;
    tenantIds: string | number;
    servicesType: string | number;
    status: string | number;
    permitScope: string | number;
    page: string | number;
    permitType: string | number;
    indefinitePage: string | number;
    temporaryPage: string | number;
    oneDayPage: string | number;
};

type VehicleListByPermitType = {
    pagingIndefiniteList: Pagination<VehicleListWithZone>;
    pagingTemporaryList: Pagination<VehicleListWithZone>;
    pagingOneDayList: Pagination<VehicleListWithZone>;
};

const permitTypeMap: Record<PermitTypeVl, keyof VehicleListByPermitType> = {
    [PermitTypeVl.Indefinite]: 'pagingIndefiniteList',
    [PermitTypeVl.Temporary]: 'pagingTemporaryList',
    [PermitTypeVl.Once]: 'pagingOneDayList',
};

function VehicleListPage(props: VehicleListEmbedProps) {
    const [selectFirst, setSelectFirst] = useState<'location' | 'company'>();
    const isGlobal = !props.locationId && !props.zoneId;

    const { permission } = useContext(AuthContext);
    const { services, getService, getPermission } = useContext(ServiceContext);
    const readOnly = permission === AuthPermission.READ_ONLY;

    const _initState: State = {
        ...initState,
        filter: {
            ...initState.filter,
            location: props.locationId ? ({ id: Number(props.locationId) } as Location) : undefined,
            zones: props.zoneId ? [{ id: Number(props.zoneId) } as Zone] : undefined,
        },
    };

    const [state, setState] = useState<State>(_initState);

    const [getListLoading, setGetListLoading] = useState(true);
    const [isLoadingGetVehicleList, setIsLoadingGetVehicleList] = useState(true);
    const [loadingFilter, setLoading] = useState({
        location: isGlobal,
        company: isGlobal,
        region: false,
        zones: !!props.locationId,
        tenants: !!props.locationId,
    });
    const isLoadingFilter = Object.values(loadingFilter).some((v) => v);
    const [deletingItem, setDeletingItem] = useState<VehicleListWithZone>();

    const [pagingVehicleList, setPagingVehicleList] = useState<Pagination<VehicleListWithZone>>({
        page: 1,
        data: [],
        pageSize: 10,
        total: 0,
        totalPage: 0,
    });

    const [pagingVehicleListByPermitType, setPagingVehicleListByPermitType] = useState<VehicleListByPermitType>({
        pagingIndefiniteList: initPagination,
        pagingTemporaryList: initPagination,
        pagingOneDayList: initPagination,
    });
    const [hasChangedPermitTypePage, setHasChangedPermitTypePage] = useState(false);

    const setVehicleListByPermitType = (tabPermitType: PermitTypeVl, data: Pagination<VehicleListWithZone>) => {
        setPagingVehicleListByPermitType((prev) => ({
            ...prev,
            [permitTypeMap[tabPermitType]]: data,
        }));
    };

    const getVehicleListByPermitType = (): Pagination<VehicleListWithZone> => {
        return pagingVehicleListByPermitType[permitTypeMap[tabValue]];
    };

    const filter = state.filter;

    const [locationDefault, setLocationDefault] = useState<Location[]>([]);
    const [locations, setLocations] = useState<Location[]>(locationDefault);
    const [companiesDefault, setCompaniesDefault] = useState<Company[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const setFilter = (filter: Partial<Filter>) => {
        setState((prev) => {
            const newFilter = { ...prev, page: 1, filter: { ...prev.filter, ...filter } };
            return newFilter;
        });
    };

    const [zones, setZones] = useState<Zone[]>([]);
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [companyInfo, setCompanyInfo] = useState<Company>({} as Company);
    const [loadingGetCompanyInfo, setLoadingGetCompanyInfo] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams({});

    const locationId = searchParams.get('locationId');

    const zoneIds = searchParams.get('zoneIds');
    const _zoneIds = zoneIds?.split(',').map(Number);
    const searchBy = searchParams.get('searchBy');
    const search = searchParams.get('search');
    const companyId = searchParams.get('companyId');
    const region = searchParams.get('region');
    const tenantIds = searchParams.get('tenantIds');
    const _tenantIds = tenantIds?.split(',').map(Number);
    const servicesType = searchParams.get('servicesType');
    const _service = services?.find((item) => item.id === Number(servicesType));
    const status = searchParams.get('status');
    const _status = statusFilter?.find((item) => item.id === Number(status));
    const permitScope = searchParams.get('permitScope');
    const _permitScope = permitScope ? permitScopes.find((item) => item.id === Number(permitScope)) : permitScopes[0];
    const page = searchParams.get('page');
    const permitType = searchParams.get('permitType');
    const indefinitePage = searchParams.get('indefinitePage');
    const temporaryPage = searchParams.get('temporaryPage');
    const oneDayPage = searchParams.get('oneDayPage');

    const isIPermitOrInternalPermit = Number(servicesType) === 2 || Number(servicesType) === 3;

    const [tabValue, setTabValue] = useState<PermitTypeVl>(
        isNull(permitType) ? PermitTypeVl.Indefinite : Number(permitType)
    );

    const handleChangeParams = (params: Partial<SearchParams>) => {
        const _searchParams: any = {
            locationId,
            zoneIds,
            companyId,
            region,
            tenantIds,
            servicesType,
            status,
            permitScope,
            searchBy,
            search,
            permitType,
            indefinitePage,
            temporaryPage,
            oneDayPage,
            page: 1,
            ...params,
        };
        Object.entries(_searchParams).forEach(([key, value]) => {
            const firstPage = key === 'page' && Number(value) === 1;
            const firstIndefinitePage = key === 'indefinitePage' && Number(value) === 1;
            const firstTemporaryPage = key === 'temporaryPage' && Number(value) === 1;
            const firstOneDayPage = key === 'oneDayPage' && Number(value) === 1;
            const allListPermitScope = key === 'permitScope' && Number(value) === -1;
            const permitScopeGlobal = key === 'permitScope' && value && Number(value) === 0;
            const isRemove = !value && !(key === 'permitScope' && value === 0);
            if (
                (isRemove ||
                    (key === 'permitScope' && Number(value) === -1) ||
                    firstPage ||
                    firstIndefinitePage ||
                    firstTemporaryPage ||
                    firstOneDayPage ||
                    allListPermitScope) &&
                !permitScopeGlobal
            ) {
                delete (_searchParams as any)[key];
            }
        });

        setSearchParams(new URLSearchParams(_searchParams));
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        handleChangeParams({ permitType: `${newValue}` });
    };

    const regionAutocomplete = useStyledAutocomplete({
        loading: loadingFilter.region,
        value: filter.region,
        disabled: true,
        onChange: (r) => {
            const _locationsFiltered = locationDefault.filter((item) => item.regionId === r?.id ?? 0);
            setLocations(_locationsFiltered);
            setHasChangedPermitTypePage(false);
            handleChangeParams({
                region: r?.id,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
            setFilter({ region: r });
        },
        getAllList: (props: FilterRequest<Region>) =>
            regionController.getAll(props).then((res) => {
                setLoading((prev) => ({ ...prev, region: false } as any));
                if (filter.location?.regionId && filter.company?.id) {
                    setFilter({ region: res.data.find((r) => r.id === filter.location?.regionId) });
                } else {
                    if (region) {
                        const _regionParams = res.data.find((r) => r.id === Number(region));
                        console.log('region', _regionParams);
                        setFilter({ region: _regionParams });
                    } else {
                        console.log('region null');
                        setFilter({ region: undefined });
                    }
                }
                return res;
            }),
        listProps: {
            page: 1,
            pageSize: 20,
            search: { fields: ['name'], value: '' },
            filter: {
                companyId: filter.company?.id || 0,
            },
        },
        dependencyList: [filter.company?.id, filter.company && !filter.location],
    });

    const getLocation = async () => {
        const _filterLocation: FilterLocation = {
            page: 1,
            pageSize: 10000,
            filter: { companyId: 0, regionId: 0 },
            search: {
                fields: ['name'],
                value: '',
            },
        };
        const _locations = await locationController.getAll(_filterLocation, 'getLocation');
        setLocationDefault(_locations.data);
        setLocations(_locations.data);
        setLoading((prev) => ({ ...prev, location: false } as any));
        if (locationId) {
            const _location = _locations.data.find((item) => item.id === Number(locationId));
            if (_location) {
                const isSameCompany = _location.companyId === filter.company?.id;
                const isSameRegion = _location.regionId === filter.region?.id;
                setFilter({ location: _location });
                setGetListLoading(true);
                setLoading((prev) => ({
                    ...prev,
                    zones: true,
                    region: !isSameRegion,
                    tenants: true,
                    location: !isSameCompany,
                }));
                const _zones = await zoneController.getZones(_location!.id);
                setZones(_zones);
                const _zoneParams = _zones.filter((item) => _zoneIds?.includes(item.id));
                const _tenant = await getTenants(_location.id);
                setTenants(_tenant.data);
                const _tenantParams = _tenant.data.filter((item) => _tenantIds?.includes(item.clientId));
                const region = isSameRegion ? filter.region : await regionController.getById(_location.regionId);
                setFilter({ zones: _zoneParams, tenants: _tenantParams, region: region });
                setLoading((prev) => ({
                    ...prev,
                    zones: false,
                    company: false,
                    region: false,
                    tenants: false,
                    location: false,
                }));
            }
        }
        return _locations;
    };

    const getCompany = async () => {
        const _filter: FilterRequest<Company> = {
            page: 1,
            pageSize: 10000,
            search: {
                fields: ['displayName'],
                value: '',
            },
        };
        const _company = await companyController.getAll(_filter);
        const _companyParams = _company.data.find((item) => item.id === Number(companyId));
        setFilter({ company: _companyParams });
        setCompanies(_company.data);
        setCompaniesDefault(_company.data);
        setLoading((prev) => ({ ...prev, company: false } as any));

        return _company;
    };

    const onChangeLocation = async (l?: Location | undefined) => {
        setHasChangedPermitTypePage(false);
        if (l) {
            if (!selectFirst) setSelectFirst('location');
            const isSameCompany = l.companyId === filter.company?.id;
            const isSameRegion = l.regionId === filter.region?.id;
            setGetListLoading(true);
            setFilter({ location: l });
            handleChangeParams({ locationId: l.id });
            setLoading((prev) => ({
                ...prev,
                zones: true,
                region: !isSameRegion,
                tenants: true,
                location: !isSameCompany,
            }));
            const _zones = await zoneController.getZones(l.id);
            const _tenant = await getTenants(l.id);

            const company =
                l.companyId !== undefined ? companiesDefault.find((item) => item.id === l.companyId) : undefined;
            const region = isSameRegion ? filter.region : await regionController.getById(l.regionId);
            setZones(_zones);
            setTenants(_tenant.data);
            setFilter({ zones: _zones, company, region, tenants: [] });
            const zoneIdsParam = _zones.map((item: Zone) => encodeURIComponent(item.id)).join(',');
            handleChangeParams({
                locationId: l.id,
                companyId: company!.id as number,
                region: region.id as number,
                tenantIds: undefined,
                zoneIds: zoneIdsParam,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
            setLoading((prev) => ({
                ...prev,
                zones: false,
                company: false,
                region: false,
                tenants: false,
                location: false,
            }));
        } else {
            if (selectFirst === 'location') setSelectFirst(undefined);
            handleChangeParams({
                locationId: undefined,
                region: undefined,
                tenantIds: undefined,
                zoneIds: undefined,
                companyId: undefined,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
            setFilter({
                location: l,
                zones: [],
                region: undefined,
                tenants: [],
                company: undefined,
            });
        }
    };

    const serviceAutocomplete = useStyledAutocomplete({
        value: _service,
        onChange: (s) => {
            const isPermitOrInternalPermit = s?.id === 2 || s?.id === 3;
            setHasChangedPermitTypePage(false);
            handleChangeParams({
                servicesType: s?.id,
                permitType: isPermitOrInternalPermit ? PermitTypeVl.Indefinite : undefined,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
            setTabValue(PermitTypeVl.Indefinite);
        },
        isSync: true,
        list: {
            options: services || [],
            isFiltered: (option, searchText) => searchValue(option.name, searchText),
        },
        dependencyList: [services],
    });

    const statusAutocomplete = useStyledAutocomplete({
        value: _status,
        onChange: (s) => {
            setHasChangedPermitTypePage(false);
            handleChangeParams({
                status: s?.id,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
        },
        isSync: true,
        list: {
            options: statusFilter,
            isFiltered: (option, searchText) => searchValue(option.name, searchText),
        },
    });

    const permitScopeAutocomplete = useStyledAutocomplete({
        value: _permitScope,
        disabled: !isGlobal,
        onChange: (s) => {
            setHasChangedPermitTypePage(false);
            handleChangeParams({
                permitScope: s?.id,
                indefinitePage: undefined,
                temporaryPage: undefined,
                oneDayPage: undefined,
            });
        },
        isSync: true,
        list: {
            options: permitScopes,
            isFiltered: (option, searchText) => searchValue(option.name, searchText),
        },
    });

    const handleChangeSearch = useDbSearch({
        fn: (text: string) => setState((prev) => ({ ...prev, page: 1, search: { ...prev.search, value: text } })),
    });

    const handleChangeType = (type: SearchType) => {
        handleChangeParams({ searchBy: type.id });
        setState(
            (prev) =>
                ({
                    ...prev,
                    search: {
                        ...prev.search,
                        type,
                    },
                } as any)
        );
    };

    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setValue(state.search.value);
    }, [state.search.value]);
    const zoneIdProps = Number(props.zoneId);
    const locationIdProps = Number(props.locationId)
    const getVehicles = async (
        props?: Partial<FilterVehicleListProps>,
        disabledScroll?: boolean,
        currentTabPermitType?: number
    ) => {
        await vehicleListController
            .filter({
                page: 1,
                pageSize: 10,
                filter: {
                    companyId: Number(companyId) || 0,
                    locationId: locationIdProps ? locationIdProps : Number(locationId) || 0,
                    regionId: Number(region) || 0,
                    scopeType: permitScope !== undefined && permitScope !== null ? Number(permitScope) : -1,
                    serviceId: Number(servicesType) || 0,
                    status: Number(status) || 0,
                    zoneIds: zoneIdProps ? [zoneIdProps]:  _zoneIds || [],
                    tenantIds: _tenantIds || [],
                    permitTypeVl: isIPermitOrInternalPermit ? currentTabPermitType ?? tabValue : undefined,
                },
                ...props,
                search: {
                    fields: [searchBy ?? searchTypes[1].id],
                    value: props?.search?.value || search || '',
                },
            })
            .then((res) => {
                if (isIPermitOrInternalPermit) {
                    setVehicleListByPermitType(currentTabPermitType ?? tabValue, res);
                } else {
                    setPagingVehicleList(res);
                }
                !disabledScroll &&
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
            });
    };

    const getPageByPermitType = (permitTypeProps?: FilterVehicleListByPermitTypeProps) => {
        switch (permitTypeProps?.permitType) {
            case PermitTypeVl.Indefinite:
                return permitTypeProps?.indefinitePage;
            case PermitTypeVl.Temporary:
                return permitTypeProps?.temporaryPage;
            case PermitTypeVl.Once:
                return permitTypeProps?.oneDayPage;
        }
    };

    const getVehicleList = useCallback(
        async (
            props?: Partial<FilterVehicleListProps>,
            permitTypeProps?: FilterVehicleListByPermitTypeProps,
            disabledScroll?: boolean
        ) => {
            setGetListLoading(!isIPermitOrInternalPermit);
            if (isIPermitOrInternalPermit) {
                setIsLoadingGetVehicleList(true);
                if (permitTypeProps?.hasChangedPermitTypePage) {
                    await getVehicles(
                        { ...props, page: getPageByPermitType(permitTypeProps) },
                        disabledScroll,
                        permitTypeProps?.permitType
                    );
                } else {
                    await Promise.all([
                        getVehicles(
                            { ...props, page: permitTypeProps?.indefinitePage },
                            disabledScroll,
                            PermitTypeVl.Indefinite
                        ),
                        getVehicles(
                            { ...props, page: permitTypeProps?.temporaryPage },
                            disabledScroll,
                            PermitTypeVl.Temporary
                        ),
                        getVehicles({ ...props, page: permitTypeProps?.oneDayPage }, disabledScroll, PermitTypeVl.Once),
                    ]);
                }
                setIsLoadingGetVehicleList(false);
            } else {
                await getVehicles(props, disabledScroll);
            }
            setGetListLoading(false);
        },
        [locationId, zoneIds, companyId, region, tenantIds, servicesType, status, permitScope, searchBy, search, page]
    );

    useEffect(() => {
        if (props.locationId) {
            zoneController.getZones(Number(props.locationId)).then((res) => {
                setFilter({ zones: res });
                setZones(res);
                const zoneIdsParam = res.map((item: Zone) => encodeURIComponent(item.id)).join(',');
                getTenants(Number(props.locationId))
                    .then((pagination) => {
                        setFilter({ tenants: [] });
                        setTenants(pagination.data);
                    })
                    .finally(() => {
                        handleChangeParams({zoneIds:zoneIdsParam,tenantIds: undefined });
                        setLoading((prev) => ({ ...prev, zones: false, tenants: false }));
                    });
            });
        }
    }, [props.locationId]);

    useEffect(() => {
        if (props.zoneId) {
            getTenants(0, Number(props.zoneId))
                .then((pagination) => {
                    setFilter({ tenants: [] });
                    handleChangeParams({tenantIds: undefined });
                    setTenants(pagination.data);
                })
                .finally(() => {
                    setLoading((prev) => ({ ...prev, tenants: false }));
                });
        }
    }, [props.zoneId]);

    const getTenants = async (locationId?: number, zoneId?: number) => {
        return await tenantController.filter({
            page: 1,
            pageSize: 10000,
            filter: {
                locationId: locationId ?? 0,
                zoneIds: zoneId ? [zoneId] : [],
                vehicleListId: 0,
            },
        });
    };

    useEffect(() => {
        if (isLoadingFilter) return;
        getVehicleList(
            {
                page: Number(page) || 1,
                search: { fields: [state.search.type.id], value: state.search.value },
            },
            {
                permitType: Number(permitType),
                indefinitePage: Number(indefinitePage) || 1,
                temporaryPage: Number(temporaryPage) || 1,
                oneDayPage: Number(oneDayPage) || 1,
                hasChangedPermitTypePage: hasChangedPermitTypePage,
            }
        );
    }, [
        JSON.stringify(filter),
        Number(page),
        Number(indefinitePage),
        Number(temporaryPage),
        Number(oneDayPage),
        hasChangedPermitTypePage,
        state.search.value,
        isLoadingFilter,
        servicesType,
        status,
        permitScope,
    ]);

    useEffect(() => {
        if (isLoadingFilter || !state.search.value) return;
        getVehicleList({
            page: Number(page) || 1,
            search: { fields: [state.search.type.id], value: state.search.value },
        });
    }, [state.search.type.id, isLoadingFilter]);

    const getUrlRedirect = () => {
        const url = `${appConfig.gateway.iPermitURL}/permits/add-permits?isFromStel=true&companyId=${companyInfo.id}`;
        if (props.zoneId) return url + `&locationId=${companyInfo.locations![0].id}&zoneId=${props.zoneId}`;
        if (props.locationId) return url + `&locationId=${props.locationId}`;
        return url + `&locationId=${props.locationId}`;
    };

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    useEffect(() => {
        if (!isLoadingFilter && !isGlobal) {
            companyController
                .CompanyByChildId({
                    locationId: props.locationId ? Number(props.locationId) : 0,
                    zoneId: props.zoneId ? Number(props.zoneId) : 0,
                })
                .then((res) => setCompanyInfo(res))
                .finally(() => {
                    setLoadingGetCompanyInfo(false);
                });
        }
    }, [isLoadingFilter]);
    useEffect(() => {
        const _companyId = (selectFirst === 'location' ? 0 : filter.company?.id) || 0;
        const _regionId = (selectFirst === 'location' ? 0 : filter.region?.id) || 0;
        if (_companyId === 0 && _regionId === 0) {
            setLocations(locationDefault);
        } else {
            const _locationsFiltered = locationDefault.filter(
                (item) => item.companyId === _companyId && (_regionId === 0 || item.regionId === _regionId)
            );

            setLocations(_locationsFiltered);
        }
    }, [filter.company?.id, filter.region?.id]);

    const initParamsFilter = () => {
        if (!searchParams) return;
        setValue(search ?? '');
        handleChangeSearch(search ?? '');
        const _searchType = searchTypes.find((item) => item.id === searchBy);
        if (_searchType) {
            setState(
                (prev) =>
                    ({
                        ...prev,
                        search: {
                            ...prev.search,
                            type: _searchType,
                        },
                    } as any)
            );
        }
    };
    const init = async () => {
        await Promise.all([getLocation(), getCompany()]).then(() => {
            initParamsFilter();
        });
    };

    useEffect(() => {
        if (isIPermitOrInternalPermit) {
            if (isNull(permitType)) {
                handleChangeParams({ permitType: PermitTypeVl.Indefinite });
            }
        }
        init();
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ px: '12px', pt: isGlobal ? 0 : 3 }}>
                <GFlexRowCenter justifyContent={'space-between'}>
                    <TitleWithBackButton variant="h3">
                        {/* <BsFillArrowLeftSquareFill />  */}
                        Vehicle lists
                    </TitleWithBackButton>

                    <GFlexRowCenter justifyContent={'end'} gap={2}>
                        {!isGlobal && (
                            <LoadingButton
                                loading={loadingGetCompanyInfo}
                                loadingPosition="start"
                                disabled={loadingGetCompanyInfo}
                                startIcon={<></>}
                                variant="outlinedGreen"
                                sx={{
                                    minWidth: 165,
                                    background: color.success,
                                    color: color.white,
                                    '&:hover': {
                                        background: '#2E7D32',
                                        color: 'white',
                                    },
                                    '& .MuiLoadingButton-loadingIndicator': {
                                        left: '15px',
                                    },
                                }}
                                onClick={() => openInNewTab(getUrlRedirect())}
                            >
                                Add permit
                            </LoadingButton>
                        )}

                        <LinkTo to={`${props.onAddVehicleList()}`}>
                            <Button
                                id="btn-add-vehicle-list"
                                variant="contained"
                                onClick={() => props.onAddVehicleList()}
                                sx={{ px: 4, Width: 165 }}
                                disabled={readOnly}
                            >
                                Add vehicle list
                            </Button>
                        </LinkTo>
                    </GFlexRowCenter>
                </GFlexRowCenter>

                <FilterRoot
                    baseStyle={{ mt: 2 }}
                    rightTitleContent={
                        <Stack direction={'row'} spacing={2}>
                            {/* <Stack sx={{ maxWidth: 105 }}>
                                <StyledAutocomplete
                                    {...searchTypeAutocomplete}
                                    label="Search by"
                                    getOptionLabel={(option) => option.name}
                                />
                            </Stack> */}

                            <SearchField
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                                value={value}
                                selectType={state.search.type}
                                onChangeType={handleChangeType}
                                onChange={(e) => {
                                    const _value = e.target.value;
                                    setValue(_value);
                                    setHasChangedPermitTypePage(false);
                                    handleChangeParams({
                                        search: _value,
                                        indefinitePage: undefined,
                                        temporaryPage: undefined,
                                        oneDayPage: undefined,
                                    });
                                    focus && handleChangeSearch(_value);
                                }}
                            />
                        </Stack>
                    }
                    handleResetFilter={() => {
                        setHasChangedPermitTypePage(false);
                        handleChangeParams({
                            locationId: undefined,
                            region: undefined,
                            tenantIds: undefined,
                            zoneIds: undefined,
                            companyId: undefined,
                            page: undefined,
                            permitScope: undefined,
                            search: undefined,
                            servicesType: undefined,
                            searchBy: undefined,
                            status: undefined,
                            permitType: undefined,
                            indefinitePage: undefined,
                            temporaryPage: undefined,
                            oneDayPage: undefined,
                        });
                        if (JSON.stringify(_initState) === JSON.stringify(state)) return;
                        setSelectFirst(undefined);
                        setState(_initState);
                        setLocations(locationDefault);
                        setCompanies(companiesDefault);
                        // setLoading((prev) => ({ ...prev, location: true, company: true } as any));
                        // getLocation();
                        // getCompany();
                    }}
                    filterChild={
                        <Grid item container xs={12} spacing={2}>
                            {isGlobal && (
                                <>
                                    <Grid item xs={12}>
                                        <AutocompleteNoPagi
                                            pagingDefault={locationDefault}
                                            disabled={!global}
                                            value={filter.location}
                                            paging={locations}
                                            hasNextPage={false}
                                            isNextPageLoading={true}
                                            loadNextPage={(res) => {
                                                console.log(res);
                                            }}
                                            onChange={onChangeLocation}
                                            loading={loadingFilter.location}
                                            searchLoading={false}
                                            label="Location"
                                            placeholder="All locations"
                                            getOptionLabel={(option) => option.name}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <AutocompleteNoPagi
                                            pagingDefault={companiesDefault}
                                            value={filter.company}
                                            paging={companies}
                                            hasNextPage={false}
                                            isNextPageLoading={true}
                                            loadNextPage={(res) => {
                                                console.log(res);
                                            }}
                                            disabled={!isGlobal || !!filter.location}
                                            onChange={(r) => {
                                                // setLoading((p) => ({ ...p, location: true }));
                                                if (!selectFirst) setSelectFirst('company');
                                                else if (selectFirst === 'company' && !r) setSelectFirst(undefined);
                                                setHasChangedPermitTypePage(false);
                                                handleChangeParams({
                                                    companyId: r?.id,
                                                    indefinitePage: undefined,
                                                    temporaryPage: undefined,
                                                    oneDayPage: undefined,
                                                });
                                                setFilter({ company: r });
                                            }}
                                            loading={loadingFilter.company}
                                            searchLoading={false}
                                            label="Company"
                                            placeholder="All companies"
                                            getOptionLabel={(option) => option.displayName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <StyledAutocomplete
                                            {...regionAutocomplete}
                                            label="Region"
                                            placeholder="All regions"
                                            getOptionLabel={(option) => option.name}
                                        />
                                    </Grid>
                                </>
                            )}

                            {!props.zoneId && (
                                <Grid item xs={isGlobal ? 6 : 12}>
                                    <IMultiSelectWithCheckBox
                                        limitTags={isGlobal ? 1 : 3}
                                        label={'Zone'}
                                        keyId={'id'}
                                        keyLabel={'publicName'}
                                        listData={zones}
                                        isDisable={(isGlobal ? !filter.location : false) || loadingFilter.zones}
                                        value={filter.zones ?? []}
                                        setFilter={(option) => {
                                            const zoneIdsParam = option
                                                .map((item: Zone) => encodeURIComponent(item.id))
                                                .join(',');
                                            setHasChangedPermitTypePage(false);
                                            handleChangeParams({
                                                zoneIds: zoneIdsParam,
                                                indefinitePage: undefined,
                                                temporaryPage: undefined,
                                                oneDayPage: undefined,
                                            });
                                            setFilter({ zones: option });
                                        }}
                                        chipSize={isGlobal ? undefined : 300}
                                    />
                                </Grid>
                            )}
                            {
                                <Grid item xs={isGlobal ? 6 : 12}>
                                    <IMultiSelectWithCheckBox
                                        limitTags={isGlobal ? 1 : 3}
                                        label={'Tenant'}
                                        keyId={'clientId'}
                                        keyLabel={'tenantName'}
                                        listData={tenants}
                                        isDisable={(isGlobal ? !filter.location : false) || loadingFilter.tenants}
                                        value={filter.tenants ?? []}
                                        setFilter={(option) => {
                                            const _tenantParams = option
                                                .map((item: Tenant) => encodeURIComponent(item.clientId))
                                                .join(',');
                                            setHasChangedPermitTypePage(false);
                                            handleChangeParams({
                                                tenantIds: _tenantParams,
                                                indefinitePage: undefined,
                                                temporaryPage: undefined,
                                                oneDayPage: undefined,
                                            });
                                            setFilter({ tenants: option });
                                        }}
                                        chipSize={isGlobal ? undefined : 300}
                                    />
                                </Grid>
                            }

                            <Grid item xs={isGlobal ? 4 : 6}>
                                <StyledAutocomplete
                                    {...serviceAutocomplete}
                                    label="Service"
                                    placeholder="All services"
                                    getOptionLabel={(option) => option.name}
                                />
                            </Grid>
                            <Grid item xs={isGlobal ? 4 : 6}>
                                <StyledAutocomplete
                                    {...statusAutocomplete}
                                    label="Status"
                                    placeholder="All statuses"
                                    getOptionLabel={(option) => option.name}
                                />
                            </Grid>
                            {isGlobal && (
                                <Grid item xs={4}>
                                    <StyledAutocomplete
                                        {...permitScopeAutocomplete}
                                        label="Permit scope"
                                        disabledAllOption
                                        getOptionLabel={(option) => option.name}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    }
                />

                <LoadingWrap loading={getListLoading} rows={isIPermitOrInternalPermit ? [1] : pagingVehicleList.data}>
                    <Box mt={3} mb={10}>
                        <Stack direction={'row'} justifyContent="flex-end">
                            {isIPermitOrInternalPermit ? (
                                <Typography color="success.main">
                                    Total vehicle lists:{' '}
                                    {pagingVehicleListByPermitType.pagingIndefiniteList.total +
                                        pagingVehicleListByPermitType.pagingTemporaryList.total +
                                        pagingVehicleListByPermitType.pagingOneDayList.total}
                                </Typography>
                            ) : (
                                <Typography color="success.main">
                                    Total vehicle lists: {pagingVehicleList.total}
                                </Typography>
                            )}
                        </Stack>

                        {isIPermitOrInternalPermit ? (
                            <Box sx={{ width: '100%' }}>
                                <Box>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChange}
                                        sx={{
                                            mt: 2,
                                            '&.MuiTabs-root': {
                                                minHeight: 'auto',
                                            },
                                            '& .MuiTab-root': {
                                                px: '16px',
                                                minWidth: 'auto',
                                                textTransform: 'none',
                                            },
                                            '& .MuiTabs-indicator': {
                                                background: color.primary,
                                            },
                                            '& .Mui-selected': {
                                                color: color.primary,
                                                '&.MuiTab-root': {
                                                    background: color.lightPrimary,
                                                },
                                            },
                                        }}
                                    >
                                        <Tab
                                            value={PermitTypeVl.Indefinite}
                                            label={`Indefinite (${pagingVehicleListByPermitType.pagingIndefiniteList.total})`}
                                        />
                                        <Tab
                                            value={PermitTypeVl.Temporary}
                                            label={`Temporary (${pagingVehicleListByPermitType.pagingTemporaryList.total})`}
                                        />
                                        <Tab
                                            value={PermitTypeVl.Once}
                                            label={`One day (${pagingVehicleListByPermitType.pagingOneDayList.total})`}
                                        />
                                    </Tabs>
                                </Box>

                                <Box
                                    sx={{
                                        padding: '16px',
                                        border: '1px solid #ddd',
                                        borderRadius: '0px 0px 8px 8px',
                                    }}
                                >
                                    <LoadingWrap2
                                        loading={isLoadingGetVehicleList}
                                        rows={getVehicleListByPermitType().data}
                                    >
                                        {[1, 2, 3].map((_, idx) => (
                                            <CustomTabPanel value={tabValue} index={idx}>
                                                <Stack spacing={2}>
                                                    {getVehicleListByPermitType().data.map((vehicle) => {
                                                        return (
                                                            <VehicleListItem
                                                                key={vehicle.id}
                                                                vehicle={vehicle}
                                                                onChangeStatusSuccess={(vehicle) => {
                                                                    if (filter.status) {
                                                                        getVehicleList();
                                                                    } else {
                                                                        setPagingVehicleList((prev) => ({
                                                                            ...prev,
                                                                            data: prev.data.map((d) =>
                                                                                d.id === vehicle.id
                                                                                    ? { ...d, status: vehicle.status }
                                                                                    : d
                                                                            ),
                                                                        }));
                                                                    }
                                                                }}
                                                                onDelete={(item) => setDeletingItem(item)}
                                                                {...props.vehicleListItemAction}
                                                            />
                                                        );
                                                    })}
                                                </Stack>
                                                {getVehicleListByPermitType().totalPage > 1 && (
                                                    <Stack mt={3} direction={'row'} justifyContent="center">
                                                        <MuiPagination
                                                            count={getVehicleListByPermitType().totalPage || 1}
                                                            page={getVehicleListByPermitType().page}
                                                            onChange={(_, page) => {
                                                                switch (tabValue) {
                                                                    case PermitTypeVl.Indefinite:
                                                                        handleChangeParams({ indefinitePage: page });
                                                                        break;
                                                                    case PermitTypeVl.Temporary:
                                                                        handleChangeParams({ temporaryPage: page });
                                                                        break;
                                                                    case PermitTypeVl.Once:
                                                                        handleChangeParams({ oneDayPage: page });
                                                                        break;
                                                                }
                                                                setHasChangedPermitTypePage(true);
                                                                // setState((prev) => ({ ...prev, page: page }))
                                                            }}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                    </Stack>
                                                )}
                                            </CustomTabPanel>
                                        ))}
                                    </LoadingWrap2>
                                </Box>
                            </Box>
                        ) : (
                            <>
                                <Stack spacing={2}>
                                    {pagingVehicleList.data.map((vehicle, index) => {
                                        return (
                                            <VehicleListItem
                                                key={vehicle.id}
                                                vehicle={vehicle}
                                                onChangeStatusSuccess={(vehicle) => {
                                                    if (filter.status) {
                                                        getVehicleList();
                                                    } else {
                                                        setPagingVehicleList((prev) => ({
                                                            ...prev,
                                                            data: prev.data.map((d) =>
                                                                d.id === vehicle.id
                                                                    ? { ...d, status: vehicle.status }
                                                                    : d
                                                            ),
                                                        }));
                                                    }
                                                }}
                                                onDelete={(item) => setDeletingItem(item)}
                                                {...props.vehicleListItemAction}
                                            />
                                        );
                                    })}
                                </Stack>

                                {pagingVehicleList.totalPage > 1 && (
                                    <Stack mt={3} direction={'row'} justifyContent="center">
                                        <MuiPagination
                                            count={pagingVehicleList.totalPage || 1}
                                            page={pagingVehicleList.page}
                                            onChange={(_, page) => {
                                                handleChangeParams({ page: page });
                                                // setState((prev) => ({ ...prev, page: page }))
                                            }}
                                            variant="outlined"
                                            shape="rounded"
                                        />
                                    </Stack>
                                )}
                            </>
                        )}

                        <PopUpWarning
                            open={!!deletingItem}
                            title="Confirm"
                            message="Do you still wish to delete this vehicle list?"
                            onClose={() => setDeletingItem(undefined)}
                            onConfirm={() => {
                                vehicleListController
                                    .remove(deletingItem!.id)
                                    .then((res) => {
                                        getVehicleList(
                                            {
                                                page:
                                                    pagingVehicleList.page > 1 && pagingVehicleList.data.length === 1
                                                        ? pagingVehicleList.page - 1
                                                        : pagingVehicleList.page,
                                            },
                                            undefined,
                                            true
                                        );
                                        pushSuccess('Delete successfully');
                                    })
                                    .catch((err) => {
                                        pushError(err?.response?.data?.message || 'Delete fail');
                                    })
                                    .finally(() => {
                                        setDeletingItem(undefined);
                                    });
                            }}
                        />
                    </Box>
                </LoadingWrap>
            </Box>
        </ThemeProvider>
    );
}

function WrapVehicleListPage() {
    const { path, locationId, zoneId } = useVehicleListParams();

    return (
        <VehicleListPage
            locationId={locationId}
            zoneId={zoneId}
            onAddVehicleList={() => {
                return `/${path}/vehicle-list/add`;
            }}
            vehicleListItemAction={{
                onEdit(vehicle) {
                    return `/${path}/vehicle-list/edit/${vehicle.id}`;
                },
                onViewDetail(vehicle) {
                    return `/${path}/vehicle-list/${vehicle.id}`;
                },
                onAddPermit(vehicle) {
                    return `/${path}/vehicle-list/${vehicle.id}/vrn/add`;
                },
                onViewVRN(vehicle) {
                    return `/${path}/vehicle-list/${vehicle.id}/vrn`;
                },
            }}
        />
    );
}

export function WrapVehicleListPageEmbed(props: VehicleListEmbedProps) {
    return <VehicleListPage {...props} />;
}

VehicleListPage.propTypes = {
    global: PropTypes.bool,
};

export default WrapVehicleListPage;

type State = {
    page: number;
    pageSize: number;
    search: Search;
    filter: Partial<Filter>;
};

interface BaseItem<T = number> {
    name: string;
    id: T;
}

interface Filter {
    company: Company;
    region: Region;
    location: Location;
    zones: Zone[];
    tenants: Tenant[];
    // service: ServicePermit;
    scopeType: BaseItem;
    status: BaseItem;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}
