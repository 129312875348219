import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { pushError, pushSuccess } from '../../components/toast';
import color from '../../config/Colors';
import { vehicleController } from '../../controllers';
import { Vehicle } from '../../controllers/vehicle/VehicleController';
import { FormatVRNString } from '../../helpers';
import useModal from '../../hooks/useModal';
import { VehicleList, VehicleListWithZone } from '../../models/vehicleList/VehicleList';
import { VehicleListPageProps } from '../vehicleList';
import { ZoneList } from '../vehicleList/components/VehicleListItem';
import { GetVehicleListDescription } from '../vehicleList/helpers';
import { useVehicleListParams, WrapVehicleList, WrapVehicleListComponent } from '../vehicleListDetails';
import ListVRNAddItem from './components/ListVRNAddItem';
import MyModal from './components/MyModal';
import UploadVRNPage from './components/UploadVRNPage';
import useBulkUpload, { BulkUploadPermitsContext } from './hook/useBulkUpload';
import { ServiceContext } from '../../providers/ServiceProvider';

const schema = Yup.object().shape({
    vrns: Yup.array()
        .of(
            Yup.object().shape({
                vrnNumber: Yup.string().required('Field is required!'),
                // type: Yup.string().required('Field is required!'),
                bayNumber: Yup.string(),
            })
        )
        .min(1),
});

export type AddVRNAction = {
    onSaveSuccess(vehicleList: VehicleList): void;
    onCanceled(vehicleList: VehicleList): void;
};

type Props = VehicleListPageProps & {
    vehicleList: VehicleListWithZone;
    item?: Vehicle;
    action: AddVRNAction;
};

export type FormDataVRN = {
    vrns: Vehicle[];
};

const LIST_VRN_PAGE = 1;
const UPLOAD_VRN_PAGE = 2;

export function AddVRNPage(props: Props) {
    const { vehicleList } = props;
    const isAddPage = !props.item;
    const isGlobal = !props.locationId && !props.zoneId;
    const { getService, getPermission } = useContext(ServiceContext);

    const defaultPermit = props.item
        ? props.item
        : ({
              id: _.uniqueId(),
              bayNumber: '',
              firstName: '',
              lastName: '',
              permitsId: vehicleList.permits![0].id,
              type: '',
              vrnNumber: '',
          } as any);

    // console.log(`defaultPermit`, defaultPermit);

    const formData = useForm<FormDataVRN>({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            vrns: [defaultPermit],
        },
    });
    const { control, formState, setValue, getValues } = formData;

    // console.log(`formState`, formState.touchedFields);

    const fieldArray = useFieldArray({
        control,
        name: 'vrns',
    });

    const { append } = fieldArray;

    const modalState = useModal();
    const [duplicateVRN, setDuplicateVRN] = useState('');
    const [duplicateItem, setDuplicateItem] = useState<string[]>([]);
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = (data: any) => {
        setSubmitting(true);
        const _data = data.vrns.map((v: Vehicle) => {
            return { ...v, id: isAddPage ? undefined : v.id, vrnNumber: FormatVRNString(v.vrnNumber) };
        });
        // console.log(`_data`, _data);

        vehicleController
            .upSert(_data as Vehicle[])
            .then((res) => {
                const duplicateVRN: string[] = [];
                res.map((v) => (v.statusCode && v.statusCode === 400 ? duplicateVRN.push(v.vrnNumber) : ''));
                setDuplicateItem(duplicateVRN);
                if (duplicateVRN.length > 0) {
                    //show popup duplicate vrn
                    if (res.length === duplicateVRN.length) {
                        //all vrn is duplicate
                        setDuplicateVRN('All VRNs are duplicated');
                    } else {
                        setDuplicateVRN('There are some duplicate VRNs: ' + duplicateVRN.join(', '));
                    }
                    modalState.handleOpen();
                } else {
                    pushSuccess('Save successfully');
                    props.action.onSaveSuccess(props.vehicleList);
                }

                // navigate(-1);
            })
            .catch((err) => {
                pushError(err?.response?.data || 'Error');
                setSubmitting(false);
            });
    };

    const handleChangePermitType = (text: string, index: number) => {
        setValue(`vrns.${index}.type`, text, {
            shouldValidate: true,
        });
    };

    // console.log(getService(vehicleList.serviceId)?.name);

    const isInternalPermit = ['Internal permit'].includes(getService?.(vehicleList.serviceId)?.name || '');

    //upload vrns
    const [page, setPage] = useState(LIST_VRN_PAGE);
    const bulkUploadContext = useBulkUpload({ page, setPage });
    const { setPartialState, ...state } = bulkUploadContext;

    const handleAddRawDataToForm = () => {
        setValue('vrns', []);
        state.rawsExcelData.map((raw, index) =>
            append({
                ...defaultPermit,
                vrnNumber: raw.regNumber ?? '',
                firstName: raw.firstName ?? '',
                lastName: raw.lastName ?? '',
                type: raw.permitType ?? '',
                bayNumber: raw.bayInformation ?? '',
            })
        );
        setPage(LIST_VRN_PAGE);
        setPartialState(state.defaultUploadState);
    };

    const handleClose = () => {
        modalState.handleClose();
        if (getValues().vrns.length > duplicateItem.length) {
            pushSuccess('Save successfully');
            props.action.onSaveSuccess(props.vehicleList);
        }
        setSubmitting(false);
    };

    return (
        <BulkUploadPermitsContext.Provider value={bulkUploadContext}>
            <MyModal
                open={modalState.open}
                handleClose={handleClose}
                handleOpen={modalState.handleOpen}
                width={400}
                modalContent={
                    <Box p={3}>
                        <Typography variant="h3" textAlign={'center'} color={color.danger}>
                            Warning
                        </Typography>
                        <Typography py={3} textAlign={'center'}>
                            {duplicateVRN}
                        </Typography>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Button variant="contained" sx={{ minWidth: '150px' }} onClick={handleClose}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                }
            />
            <Box sx={{ px: '12px', pt: isGlobal ? 0 : 3 }}>
                <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                    <Stack spacing={1}>
                        <Stack direction={'row'} alignItems="center" spacing={1.5}>
                            <Typography variant="h4">Add permits into {vehicleList.name} vehicle list</Typography>
                            <Chip
                                size="small"
                                label={getService?.(vehicleList.serviceId)?.name}
                                sx={{ borderRadius: '16px !important' }}
                            />
                            {/* <EditIcon3 /> */}
                        </Stack>
                        <ZoneList zones={vehicleList.zones} scopeType={vehicleList.permits?.[0].scopes[0].scopeType} />
                    </Stack>
                    {page === LIST_VRN_PAGE && isInternalPermit && (
                        <Button variant="contained" onClick={() => setPage(2)}>
                            Bulk upload
                        </Button>
                    )}
                </Stack>
                <Box mt={3}>
                    <Stack direction={'row'} justifyContent="space-between">
                        <Typography>
                            Description:{' '}
                            <Typography component={'i'}>
                                {GetVehicleListDescription(vehicleList.permits?.[0], vehicleList.serviceId, isGlobal)}
                            </Typography>
                        </Typography>
                        <Stack direction={'row'} alignItems="center" spacing={2}>
                            {page === LIST_VRN_PAGE && (
                                <Typography color="success.main">Total permits: {getValues().vrns.length}</Typography>
                            )}
                        </Stack>
                    </Stack>

                    {page === LIST_VRN_PAGE && (
                        <ListVRNAddItem
                            defaultPermit={defaultPermit}
                            formData={formData}
                            handleChangePermitType={handleChangePermitType}
                            isAddPage={isAddPage}
                            item={props.item}
                        />
                    )}

                    {page === UPLOAD_VRN_PAGE && <UploadVRNPage />}
                </Box>

                <Stack mt={3} direction={'row'} justifyContent="space-between" mb={10}>
                    <Button
                        sx={{ minWidth: '200px' }}
                        variant="cancel"
                        onClick={() => {
                            page === UPLOAD_VRN_PAGE
                                ? setPage(LIST_VRN_PAGE)
                                : props.action.onCanceled(props.vehicleList);
                        }}
                    >
                        Cancel
                    </Button>

                    {page === LIST_VRN_PAGE && (
                        <LoadingButton
                            loading={submitting}
                            loadingPosition="start"
                            disabled={!formState.isValid || !isInternalPermit}
                            startIcon={<></>}
                            variant="contained"
                            sx={{
                                minWidth: '200px',
                            }}
                            type="submit"
                            onClick={() => {
                                onSubmit(getValues());
                            }}
                        >
                            Save
                        </LoadingButton>
                    )}
                    {page === UPLOAD_VRN_PAGE && (
                        <Button
                            sx={{ minWidth: '200px' }}
                            variant="contained"
                            disabled={state.progressPercent !== 100}
                            type="submit"
                            onClick={() => handleAddRawDataToForm()}
                        >
                            Next
                        </Button>
                    )}
                </Stack>
            </Box>
        </BulkUploadPermitsContext.Provider>
    );
}

export default function WrapAddVRNPage() {
    const { path } = useVehicleListParams();
    const navigate = useNavigate();

    return (
        <WrapVehicleList>
            {(vehicleList) => (
                <AddVRNPage
                    vehicleList={vehicleList}
                    action={{
                        onCanceled: () => {
                            navigate(`/${path}/vehicle-list`);
                        },
                        onSaveSuccess: () => {
                            navigate(-1);
                        },
                    }}
                />
            )}
        </WrapVehicleList>
    );
}

export function WrapEditVRNPage(props: Omit<Props, 'action' | 'vehicleList'>) {
    const { zoneId, locationId } = useVehicleListParams();
    const params = useParams<{ vrnId: string }>();
    // console.log(`params`, params);
    const vrnId = Number(params.vrnId);
    const [vrn, setVrn] = useState<Vehicle>();
    const navigate = useNavigate();

    useEffect(() => {
        vehicleController.getById(vrnId).then((res) => {
            setVrn(res);
        });
    }, [vrnId]);

    return (
        <WrapVehicleList>
            {(vehicleList) => {
                return vrn ? (
                    <AddVRNPage
                        locationId={locationId}
                        zoneId={zoneId}
                        vehicleList={vehicleList}
                        item={vrn}
                        action={{
                            onCanceled: () => {
                                navigate(-1);
                            },
                            onSaveSuccess: () => {
                                navigate(-1);
                            },
                        }}
                    />
                ) : (
                    <></>
                );
            }}
        </WrapVehicleList>
    );
}
export function WrapEditVRNComponent(
    props: VehicleListPageProps & { vrnId: string; vehicleListId: string; action: AddVRNAction }
) {
    const vrnId = Number(props.vrnId);
    const [vrn, setVrn] = useState<Vehicle>();

    useEffect(() => {
        vrnId &&
            vehicleController.getById(vrnId).then((res) => {
                setVrn(res);
            });
    }, [vrnId]);

    return (
        <WrapVehicleListComponent {...props}>
            {(vehicleList) => {
                return vrn ? (
                    <AddVRNPage
                        vehicleList={vehicleList}
                        zoneId={props.zoneId}
                        locationId={props.locationId}
                        item={vrn}
                        action={props.action}
                    />
                ) : (
                    <></>
                );
            }}
        </WrapVehicleListComponent>
    );
}
