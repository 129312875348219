import { Chip, IconButton, IconButtonProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Stack } from '@mui/system';
import { useContext, useState } from 'react';
import IOSSwitch from '../../../components/IOSSwitch';
import LinkTo from '../../../components/LinkTo';
import PopUpWarning from '../../../components/PopUpWarning';
import EditIcon2 from '../../../components/svg/EditIcon2';
import PlusIcon from '../../../components/svg/PlusIcon';
import RemoveIcon from '../../../components/svg/RemoveIcon';
import { pushSuccess } from '../../../components/toast';
import color from '../../../config/Colors';
import { vehicleListController } from '../../../controllers';
import { AuthContext, AuthPermission } from '../../../layout/AuthLayout';
import { ScopeType } from '../../../models/permit/Scope';
import { Tenant } from '../../../models/scope/Tenant';
import Zone from '../../../models/scope/Zone';
import { VehicleList, VehicleListWithZone } from '../../../models/vehicleList/VehicleList';
import { DangerTooltip, GFlexRow } from '../../../styles/style';
import { ServiceContext } from '../../../providers/ServiceProvider';
import { ServiceId } from '../../../models/vehicleList/Service';

export type VehicleListItemAction = {
    onEdit(vehicle: VehicleList): void;
    onViewDetail(vehicle: VehicleList): void;
    onAddPermit(vehicle: VehicleList): void;
    onViewVRN(vehicle: VehicleList): void;
};

type Props = Partial<VehicleListItemAction> & {
    vehicle: VehicleListWithZone;
    onChangeStatusSuccess?(vehicle: VehicleListWithZone): void;
    onDelete?(vehicle: VehicleListWithZone): any;
};

export const StyledBaseItem = (props: any) => {
    return (
        <Stack border="1px solid #DDDDDD" borderRadius="8px" padding="16px 24px" {...props}>
            {props.children}
        </Stack>
    );
};

styled(Stack)({});

export const StyledBaseIconButton = (props: IconButtonProps) => {
    return (
        <IconButton
            sx={{
                backgroundColor: color.grey200,
                '&:hover': {
                    backgroundColor: color.grey200,
                },
                border: `2px solid ${color.grey100}`,
                width: '32px',
                height: '32px',
                padding: '10px',
            }}
            {...props}
        >
            {props.children}
        </IconButton>
    );
};

export default function VehicleListItem(props: Props) {
    const { vehicle } = props;
    const { permission: p } = useContext(AuthContext);
    const { services, getPermission, getService } = useContext(ServiceContext);
    const readOnly = p === AuthPermission.READ_ONLY;
    const [popUpConfirmActive, setPopUpConfirmActive] = useState(false);

    const handleChangeActive = (vehicleListId: number) => {
        vehicleListController
            .changeStatus(vehicleListId)
            .then((res) => {
                props.onChangeStatusSuccess?.(res);
                pushSuccess('Change status successfully.');
            })
            .finally(() => {
                setPopUpConfirmActive(false);
            });
    };

    const permission = getPermission?.(vehicle.serviceId);

    const isDeleteInternalPermit = !props.vehicle.totalPermit;
    const isInternalPermit = vehicle.serviceId === ServiceId.InternalPermit;

    return (
        <StyledBaseItem spacing={'8px'}>
            <Stack direction={'row'} alignItems="center" justifyContent="space-between" sx={{ maxHeight: 21 }}>
                <Stack direction={'row'} spacing={1.5}>
                    <LinkTo to={`${props.onViewDetail?.(vehicle)}`}>
                        <Typography
                            sx={{
                                ':hover': {
                                    textDecoration: 'underline',
                                    color: (theme) => theme.palette.primary.main,
                                    cursor: 'pointer',
                                },
                            }}
                        >
                            {vehicle.name}
                        </Typography>
                    </LinkTo>

                    <Chip
                        size="small"
                        sx={{ borderRadius: '16px', height: 22, fontSize: 12 }}
                        label={getService?.(vehicle.serviceId)?.name}
                    />

                    {vehicle.isExpired && (
                        <Chip
                            size="small"
                            sx={{
                                borderRadius: '16px',
                                height: 22,
                                fontSize: 12,
                                '&.MuiChip-root': {
                                    background: 'rgba(224, 27, 0, 0.1)',
                                    color: color.danger,
                                },
                            }}
                            label={'Expired'}
                        />
                    )}
                </Stack>

                <Stack direction={'row'} spacing={1}>
                    {permission?.addPermit && (
                        <LinkTo to={`${props.onAddPermit?.(vehicle)}`}>
                            <Tooltip title={'Add permits'} placement="top">
                                <IconButton
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                        },
                                        border: `2px solid ${color.grey100}`,
                                        width: '32px',
                                        height: '32px',
                                        padding: '10px',
                                    }}
                                    // onClick={() => {
                                    //     props.onAddPermit?.(vehicle);
                                    // }}
                                    aria-label="fingerprint"
                                    // color="success"
                                    disabled={readOnly}
                                >
                                    <PlusIcon width={'26px'} height={'26px'} color="inherit" />
                                </IconButton>
                            </Tooltip>
                        </LinkTo>
                    )}

                    {permission?.addVRN && (
                        <LinkTo to={`${props.onViewVRN?.(vehicle)}`}>
                            <Tooltip title={'View vehicles'} placement="top">
                                <IconButton
                                    sx={{
                                        backgroundColor: color.grey200,
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                        },
                                        border: `2px solid ${color.grey100}`,
                                        width: '32px',
                                        height: '32px',
                                        padding: '10px',
                                    }}
                                    style={{ padding: 0 }}
                                    aria-label="fingerprint"
                                >
                                    <svg
                                        width="14"
                                        height="12"
                                        viewBox="0 0 14 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M1.855 3.38376L1.92763 3.20004C2.51913 1.72129 3.95239 0.75 5.54576 0.75H8.45424C10.0476 0.75 11.4809 1.72129 12.0724 3.20004L12.145 3.38376H12.1494C12.1826 3.37501 12.2168 3.375 12.25 3.375H14V3.38376C14 3.83876 13.8163 4.28493 13.489 4.61743C13.356 4.74868 13.2038 4.85378 13.0384 4.94128L13.0462 4.95004C13.1889 5.05504 13.3193 5.17747 13.4348 5.31747C13.6623 5.58872 13.8294 5.91253 13.9204 6.25378C13.9729 6.45503 13.9991 6.665 14 6.875V9.5H13.125V11.25H10.5V9.5H3.5V11.25H0.875V9.5H0V6.875C0 6.09625 0.367499 5.38753 0.961624 4.94128C0.796249 4.85378 0.643986 4.74868 0.510986 4.61743C0.183736 4.28493 0 3.83876 0 3.38376V3.375H1.75C1.78588 3.375 1.82087 3.37501 1.855 3.38376ZM13.125 6H11.9193C11.543 6 11.1816 6.14872 10.9156 6.41122C10.6496 6.68247 10.5 7.04135 10.5 7.4176V7.75H11.375C12.3375 7.75 13.1189 6.97126 13.125 6.00876V6ZM2.08074 6H0.875C0.875 6.97125 1.65811 7.75 2.62324 7.75C3.10624 7.75 3.5 7.75 3.5 7.75V7.4176C3.5 7.04135 3.3504 6.68247 3.0844 6.41122C2.8184 6.14872 2.45699 6 2.08074 6ZM5.54576 2.5C4.66814 2.5 3.87887 3.03378 3.5525 3.84753L3.39239 4.25H10.6076L10.4475 3.84753C10.3723 3.66378 10.2708 3.4887 10.1465 3.32245C10.0065 3.14745 9.83851 2.98993 9.64951 2.86743C9.46314 2.73618 9.25749 2.64008 9.04224 2.57883C8.85061 2.52633 8.65286 2.5 8.45424 2.5H5.54576Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        </LinkTo>
                    )}

                    {permission?.edit && (
                        <Tooltip
                            title={props.vehicle.isDefault ? 'Unable to edit default vehicle list' : 'Edit'}
                            placement="top"
                        >
                            <span>
                                <LinkTo isDisable={props.vehicle.isDefault} to={`${props.onEdit?.(vehicle)}`}>
                                    <IconButton
                                        aria-label="fingerprint"
                                        // color="success"
                                        sx={{
                                            backgroundColor: color.grey200,
                                            '& .svg-icon-stroke': {
                                                stroke: color.grey600,
                                            },
                                            '&:hover': {
                                                backgroundColor: color.grey200,
                                            },
                                            border: `2px solid ${color.grey100}`,
                                            width: '32px',
                                            height: '32px',
                                            padding: '4px',
                                        }}
                                        disabled={readOnly || props.vehicle.isDefault}
                                    >
                                        <EditIcon2 />
                                    </IconButton>
                                </LinkTo>
                            </span>
                        </Tooltip>
                    )}

                    {permission?.delete && (
                        <DangerTooltip
                            title={
                                props.vehicle.isDefault
                                    ? 'Unable to delete default vehicle list'
                                    : isDeleteInternalPermit
                                    ? 'Delete'
                                    : "Can't delete vehicle list when whitelist was generated"
                            }
                            placement="top"
                        >
                            <span>
                                <IconButton
                                    onClick={() =>
                                        isDeleteInternalPermit ? props.onDelete?.(props.vehicle) : () => {}
                                    }
                                    aria-label="fingerprint"
                                    color="error"
                                    sx={{
                                        backgroundColor: color.grey200,
                                        // '& .svg-icon-stroke': {
                                        //     stroke: color.danger,
                                        // },
                                        '&:hover': {
                                            backgroundColor: color.grey200,
                                        },
                                        border: `2px solid ${color.grey100}`,
                                        width: '32px',
                                        height: '32px',
                                        padding: '4px',
                                    }}
                                    disabled={readOnly || props.vehicle.isDefault}
                                >
                                    <RemoveIcon
                                        width={'30px'}
                                        height={'30px'}
                                        style={{
                                            color: props.vehicle.isDefault
                                                ? color.grey400
                                                : isDeleteInternalPermit
                                                ? 'red'
                                                : color.grey400,
                                        }}
                                    />
                                </IconButton>
                            </span>
                        </DangerTooltip>
                    )}

                    {permission?.changeActive && (
                        <>
                            <Tooltip
                                title={props.vehicle.isDefault ? 'Cannot de-activate default vehicle list' : ''}
                                placement="top"
                            >
                                <GFlexRow sx={{ '& label': { margin: 0 } }}>
                                    <IOSSwitch
                                        checked={vehicle.status === 1}
                                        disabled={readOnly || props.vehicle.isDefault}
                                        onChange={(e, check) => {
                                            if (isInternalPermit) {
                                                setPopUpConfirmActive(true);
                                            } else {
                                                handleChangeActive(props.vehicle.id);
                                            }
                                        }}
                                    />
                                </GFlexRow>
                            </Tooltip>
                            <PopUpWarning
                                open={popUpConfirmActive}
                                title="Confirm"
                                message="This change will take effect from tomorrow."
                                onClose={() => setPopUpConfirmActive(false)}
                                onConfirm={() => {
                                    handleChangeActive(props.vehicle.id);
                                }}
                            />
                        </>
                    )}
                </Stack>
            </Stack>
            <ZoneList zones={vehicle.zones} scopeType={vehicle.scopes[0]?.scopeType} />
            <Stack>
                <Typography color={color.primary}>Number of permits: {vehicle.totalPermit || 0}</Typography>
            </Stack>
        </StyledBaseItem>
    );
}

export const ZoneList = ({ zones, scopeType }: { zones: Zone[]; scopeType?: ScopeType }) => {
    
    if (!scopeType) {
        return <Typography color="grayText">Zone(s): Global</Typography>;
    }

    return (
        <Stack direction={'row'} alignItems="center" spacing={1}>
            <Typography
                color="grayText"
                sx={{
                    maxWidth: '80%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 1,
                }}
            >
                Zone(s):{' '}
                {zones
                    .slice(0, 3)
                    .map((z) => z.name)
                    .join(', ')}
            </Typography>
            {zones.length > 3 && (
                <Tooltip title={zones.map((z) => z.name).join(', ')} placement="top" arrow>
                    <Chip size="small" sx={{ backgroundColor: color.lightPrimary }} label={`+${zones.length - 3}`} />
                </Tooltip>
            )}
        </Stack>
    );
};

export const TenantList = ({ tenants }: { tenants: Tenant[] }) => {
    if (!tenants) {
        return <Typography color="grayText">Tenant(s): Tenant is not allowed to see this vehicle list</Typography>;
    }

    if (tenants.length === 0) {
        return <Typography color="grayText">Tenant(s): All tenants</Typography>;
    }

    return (
        <Stack direction={'row'} alignItems="center" spacing={1}>
            <Typography
                color="grayText"
                sx={{
                    maxWidth: '80%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 1,
                }}
            >
                Tenant(s):{' '}
                {tenants
                    .slice(0, 3)
                    .map((z) => z.tenantName)
                    .join(', ')}
            </Typography>
            {tenants.length > 3 && (
                <Tooltip title={tenants.map((z) => z.tenantName).join(', ')} placement="top" arrow>
                    <Chip size="small" sx={{ backgroundColor: color.lightPrimary }} label={`+${tenants.length - 3}`} />
                </Tooltip>
            )}
        </Stack>
    );
};
